<template>
  <v-popover
    class="time-offset"
    placement="bottom-center"
    trigger="hover click"
    :style="!$slots.default && 'margin-left: -24px;'"
  >
    <div class="time-offset__content">
      <Icon
        class="time-offset__icon color-danger"
        name="exclamation-circle-s"
        :size="iconSize"
      ></Icon>
      <slot></slot>
    </div>
    <template slot="popover">
      <div v-if="result">{{ $t('result') }}: <b>{{ result }}</b></div>
      <div v-if="offset">{{ $t('offset') }}: <b>{{ offset }}</b></div>
      <div v-if="comment">{{ $t('comment') }}: <b>{{ comment }}</b></div>
    </template>
  </v-popover>
</template>

<script>
export default {
  name: 'TimeOffset',
  i18n: {
    messages: {
      ru: {
        result: 'Результат',
        offset: 'Штрафное время',
        comment: 'Комментарий',
      },
      en: {
        result: 'Result',
        offset: 'Penalty time',
        comment: 'Comment',
      },
      uz: {
        result: 'Natija',
        offset: 'Jarima vaqti',
        comment: 'Izoh',
      },
    },
  },
  props: {
    result: String,
    offset: String,
    comment: String,
    iconSize: {
      type: String,
      default: 'l',
    },
  },
};
</script>

<style lang="scss" scoped>
.time-offset {
  line-height: 0;

  &__content {
    user-select: none;
  }

  &__icon {
    margin-left: -24px;
    max-width: none;

    &:last-child {
      margin: 2px 0;
    }

    & + ::v-deep span {
      vertical-align: middle;
    }
  }
}
</style>
