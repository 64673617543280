<template>
  <section class="section_pt section_pb">
    <div class="container container_wide">
      <div class="container__mobile">
        <div class="block block_wide p-0" style="max-width: 1312px;">
          <div class="block__group section-block__group_bordered-bottom">
            <Row class="block__items" align="center">
              <Col class="block__item mr-auto" :size="{md: 'auto'}" style="min-width: 0">
                <Select
                  v-model="race.val"
                  :options="raceOptions"
                  track-by="code"
                  dropdown-auto-width
                  wrap-text
                  mobile
                  @input="onRaceChange"
                ></Select>
              </Col>
              <Col class="block__item" :size="{default: 'stretch', md: 'auto'}" v-show="calcMethodText">
                <div class="text-xs">
                  <span class="d-none d-md-block text-right">
                    {{ $t('event_results.results_calculated_by') }}<br><b v-html="calcMethodText"></b>
                  </span>
                  <span class="d-md-none">
                    {{ $t('event_results.results_calculated_by') }} <b v-html="calcMethodText"></b>
                  </span>
                </div>
              </Col>
              <Col :size="{md: 'auto'}" class="d-none d-md-block"></Col>
              <Col class="block__item" :size="'auto'">
                <Button
                  variant="secondary"
                  :text="$t('event_results.see_all')"
                  :to="{name: 'Results', params: { raceCode: currentRace.code }}"
                  mobile
                ></Button>
              </Col>
            </Row>
          </div>
          <div class="block__group" v-if="isLoading">
            <Loader></Loader>
          </div>
          <div class="block__group" v-else>
            <!-- Not found : BEGIN -->
            <EmptyState
              v-show="notFound"
              :subtitle="$t('leaders.not_found')"
            ></EmptyState>
            <!-- Not found : END -->
            <!-- Server error : BEGIN -->
            <EmptyState
              v-show="serverError"
              :subtitle="$t('leaders.loading_error')"
            >
              <div class="section__group">
                <Button
                  variant="secondary"
                  :text="$t('general.retry')"
                  size="lg"
                  icon-left="undo"
                  @click.prevent="retryLoadLeaders()"
                ></Button>
              </div>
            </EmptyState>
            <!-- Server error : END -->
            <Row justify="center" vertical-gutters v-show="!serverError && !notFound">
              <!-- Absolute leaders : BEGIN -->
              <Col v-if="leadersByAbsolute.length">
                <div class="block block_wide block_bordered">
                  <Row justify="center" vertical-gutters>
                    <Col
                      class="statistics-top"
                      v-for="(result, i) in leadersByAbsolute"
                      :key="i"
                      :size="{md: '8'}"
                    >
                      <div class="statistics-top__avatar">
                        <router-link
                          :is="result.participantId ? 'router-link' : 'div'"
                          class="statistics-top__avatar-content"
                          :to="getParticipantLink(result.participantId)"
                        >
                          <Avatar
                            :username="result.name"
                            :img="result.avatarLargeImage && result.avatarLargeImage.replace('-x-', '192x192')"
                            :size="104"
                            bordered
                          ></Avatar>
                          <div class="statistics-top__place">
                            <img :src="getMedalImg(result.position, true)" alt="">
                            <b class="font-monospace">{{ result.timeResult }}</b>
                            <TimeOffset
                              class="font-monospace"
                              v-if="result.timeOffset && result.timeOffset !== '00:00'"
                              :offset="result.timeOffset"
                              style="margin-left: 6px;"
                            ></TimeOffset>
                          </div>
                        </router-link>
                      </div>
                      <div class="statistics-top__info">
                        <div class="text-md">
                          <Link
                            :is="result.participantId ? 'Link' : 'div'"
                            variant="inherit"
                            :to="getParticipantLink(result.participantId)"
                          >
                            <b>{{ result.name }}</b>
                          </Link>
                        </div>
                        <div class="text-sm mt-4">№ {{ result.bibNumber }}</div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <!-- Absolute leaders : END -->

              <!-- Leaders by gender : BEGIN -->
              <Col
                :size="{md: '12'}"
                v-for="nomination in leadersByGender"
                :key="nomination.nominationOrderCode"
              >
                <div class="block block_sm block_wide block_bordered h-100">
                  <h3 class="h3_mb">{{ nomination.nominationName }}</h3>
                  <div class="table-container">
                    <table class="table table_default table_simple table_h-bordered">
                      <colgroup>
                        <col width="1">
                      </colgroup>
                      <tbody>
                      <tr
                        class="statistics-leader"
                        v-for="(result, i) in getResultsArray(nomination.results, leadersByGenderCount)"
                        :key="result.participantId"
                      >
                        <td class="statistics-leader__medal">
                          <img :src="getMedalImg(i + 1)" :alt="i + 1">
                        </td>
                        <td>
                          <Member
                            v-if="result.name"
                            :name="result.name"
                            :img="result.avatarLargeImage && result.avatarLargeImage.replace('-x-', '80x80')"
                            :imgTo="getParticipantLink(result.participantId)"
                            :nameTo="getParticipantLink(result.participantId)"
                          >
                            <div class="text-xs mt-4">№ {{ result.bibNumber }}</div>
                          </Member>
                          <div v-else class="text-sm">--</div>
                        </td>
                        <td class="text-right text-lg font-monospace">
                          <TimeOffset
                            v-if="result.timeOffset && result.timeOffset !== '00:00'"
                            :offset="result.timeOffset"
                          ></TimeOffset>
                          <span class="v-align-middle">
                            {{ result.timeResult || '--' }}
                          </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
              <!-- Leaders by gender : END -->
              <div class="w-100"></div>
              <Col v-if="leadersByGenderAge.length">
                <Alert
                  class="results-mobile-info"
                  :text="leadersAlertInfo.text"
                  :icon="leadersAlertInfo.icon"
                  noAction
                  state="warning" />
              </Col>
              <!-- Leaders by gender & age : BEGIN -->
              <Col
                :size="{md: '12', xl: '8'}"
                v-for="nomination in leadersByGenderAge"
                :key="nomination.nominationOrderCode"
              >
                <div class="block block_sm block_wide block_bordered h-100">
                  <h3 class="h3_mb">{{ nomination.nominationName }}</h3>
                  <div class="table-container">
                    <table class="table table_default table_simple table_h-bordered">
                      <colgroup>
                        <col width="1">
                      </colgroup>
                      <tbody>
                      <tr
                        class="statistics-leader"
                        v-for="(result, i) in getResultsArray(nomination.results, leadersByGenderAgeCount)"
                        :key="result.participantId"
                      >
                        <td class="statistics-leader__num text-lg">
                          {{ i + 1 }}
                        </td>
                        <td>
                          <div class="text-sm">
                            <Link
                              :is="result.participantId ? 'Link' : 'div'"
                              variant="inherit"
                              :to="getParticipantLink(result.participantId)"
                            >
                              <b>{{ result.name || '--' }}</b>
                            </Link>
                          </div>
                          <div class="text-xs mt-4" v-show="result.bibNumber">№ {{ result.bibNumber }}</div>
                        </td>
                        <td class="text-right text-lg font-monospace">
                          <TimeOffset
                            v-if="result.timeOffset && result.timeOffset !== '00:00'"
                            :offset="result.timeOffset"
                          ></TimeOffset>
                          <span class="v-align-middle">
                            {{ result.timeResult || '--' }}
                          </span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
              <!-- Leaders by gender & age : END -->
            </Row>
          </div>
        </div>
      </div>
    </div>
    <slot name="myLaps"></slot>
  </section>
</template>

<script>
import { ResultCalcMethod } from '@/enums';
import resultsApi from '@/services/results-api';
import Select from '@rr-component-library/select/src/main';
import Member from '@/components/Member/Member.vue';
import Avatar from '@/components/Avatar/Avatar.vue';
import TimeOffset from '@/components/TimeOffset/TimeOffset.vue';
import EmptyState from '@/components/EmptyState/EmptyState.vue';

export default {
  name: 'Leaders',
  components: {
    Select,
    Member,
    Avatar,
    TimeOffset,
    EmptyState,
  },
  props: {
    race: Object,
  },
  data() {
    return {
      ResultCalcMethod,
      calcMethod: null,
      leadersByAbsolute: [],
      leadersByGender: [],
      leadersByGenderAge: [],
      notFound: false,
      isLoading: false,
      serverError: false,
    };
  },
  computed: {
    leadersAlertInfo() {
      return {
        text: this.currentRace.resultsCalculationMethodByCategory === ResultCalcMethod.Individual ? this.$t('general.resultsChipLeaders') : this.$t('general.resultsAbsoluteLeaders'),
        icon: this.currentRace.resultsCalculationMethodByCategory === ResultCalcMethod.Individual ? 'stopwatch' : 'gun',
      };
    },
    currentRace() {
      return this.race.options.find((o) => o.code === this.race.val) || {};
    },
    raceOptions() {
      return this.race.options.filter((o) => !o.disableLeaders);
    },
    calcMethodText() {
      if (this.calcMethod === ResultCalcMethod.Individual) {
        return this.$t('event_results.by_chip_time');
      }
      if (this.calcMethod === ResultCalcMethod.Absolute) {
        return this.$t('event_results.by_gun_time');
      }
      return null;
    },
    leadersByGenderCount() {
      return this.leadersByGender.reduce((max, nomination) => {
        const { length } = nomination.results || [];
        return length > max ? length : max;
      }, 0);
    },
    leadersByGenderAgeCount() {
      return this.leadersByGenderAge.reduce((max, nomination) => {
        const { length } = nomination.results || [];
        return length > max ? length : max;
      }, 0);
    },
  },
  methods: {
    getMedalImg(place, simple) {
      if (place < 1) {
        return null;
      }
      const path = place > 3
        ? 'other-place-medal.svg'
        : `${place}-place-medal${simple ? '-simple' : ''}.svg`;

      /* eslint-disable global-require, import/no-dynamic-require */
      return require(`@/assets/img/${path}`);
    },
    getParticipantLink(participantId) {
      if (!participantId) {
        return null;
      }
      return {
        name: 'Participant',
        params: { participantId },
      };
    },
    getResultsArray(arr, size) {
      const arrSize = size || 0;
      if (arr.length === arrSize) {
        return arr;
      }
      const emptyArr = Array.from(Array(arrSize)).map(() => ({}));
      return [...arr, ...emptyArr].slice(0, arrSize);
    },
    onRaceChange() {
      this.loadLeaders();
    },
    retryLoadLeaders() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      setTimeout(this.loadLeaders, 3000);
    },
    loadLeaders() {
      this.isLoading = true;
      this.calcMethod = null;
      let responseStatus = null;

      resultsApi.getLeaders({
        raceId: this.currentRace.id,
      })
        .then((response) => {
          const { data } = response;
          this.calcMethod = data.nomination.resultsCalculationMethod;
          this.leadersByAbsolute = data.nomination.results || [];
          this.leadersByGender = (data.genderNominations || []).filter((n) => n.results?.length);
          this.leadersByGenderAge = (data.genderAgeNominations || []).filter((n) => n.results?.length);

          if (this.leadersByGender.length < 2) {
            this.leadersByGender = [];
          }

          responseStatus = response.status;
          this.notFound = ![
            this.leadersByAbsolute,
            this.leadersByGender,
            this.leadersByGenderAge,
          ].some((o) => o.length);
        })
        .catch((error) => {
          responseStatus = error.response.status;
          this.notFound = responseStatus === 404;
        })
        .finally(() => {
          this.serverError = responseStatus !== 200 && responseStatus !== 404;
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.loadLeaders();
  },
};
</script>

<style lang="scss" scoped>
@import "./Leaders";
</style>
